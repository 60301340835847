import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ImageModule from "../../modules/image-render";
import ContentModule from "../../modules/content-render";
import CTALink from "../../modules/cta"
import './ColumnBlock.scss';
import googleview from "../../images/google_reviews.svg"
import ScrollAnimation from "react-animate-on-scroll";
const ColumnBlock = (props) => {
    var imagename = "page.column_block_image.image";

    let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.column_block_image_Transforms) {
        processedImages = props?.imagetransforms?.column_block_image_Transforms;
    }
    // console.log('test', props)

    return (
        <section className={`column-block-wrapper position-relative column-right`}>
            <Container>
                <Row className={`d-flex align-items-center flex-row-reverse`}>
                    <Col xl={6}>
                        <div className="column-img-wrapper img-zoom">
                        <ScrollAnimation animateIn="animate__slideInRight" animateOnce delay={100} offset={100}>
                            <ImageModule ImageSrc={props?.image} altText={props?.image?.alternativeText} imagetransforms={processedImages} renderer="pic-src" imagename={imagename} strapi_id={props?.strapi_id} classNames="img-fluid" />
                            </ScrollAnimation>
                        </div>
                    </Col>
                    <Col xl={1}></Col>
                    <Col xl={5}>
                    <ScrollAnimation animateIn="animate__slideInLeft" animateOnce delay={100} offset={100}>
                        {props?.title && <h3>{props?.title}</h3>}
                        {props?.content && <ContentModule Content={props?.content?.data?.content} />}
                        {props?.add_link?.length > 0 &&
                            <ul className="list-inline">
                                {props?.add_link?.map((item, i) => {
                                    return (
                                        <li className="list-inline-item">
                                            <CTALink class={"button button-filled"} link={item?.link} title={item?.label} target_window={item.link_type} />
                                        </li>
                                    )
                                })}
                            </ul>
                        }
                        {props?.reviews &&
                            <div className="google-reviews d-flex align-items-center">
                                <img src={googleview} alt="review" className="img-fluid" />
                                <div className="reviews-text">4.9+/5 <span>from 348 reviews</span></div>
                            </div>
                        }
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </section>



    )
}

export default ColumnBlock
