import React from "react";
import { Accordion } from "react-bootstrap";
import ContentModule from "../../modules/content-render";
import { Container, Row, Col } from "react-bootstrap";
import './Accordion.scss';
import ScrollAnimation from "react-animate-on-scroll";

const AccordionComponent = (props) => {
    return (
        <div className="accordion-wrapper">
             <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
                <Container>
                <Row>
                    <Col xl={12}>
                   
                        <h2 className="big-title">{props?.title}</h2>
                        <Accordion className="accordion">
                            {props.add_toggle.map((item, index) => (
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>{item.title}</Accordion.Header>
                                    <Accordion.Body>
                                        {item.content &&
                                            <ContentModule Content={item.content?.data?.content} />}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
            </ScrollAnimation>

        </div>
    )
}

export default AccordionComponent