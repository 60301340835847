import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/Banner/Banner"
import Banner_Cta from "../components/Banner_Cta/Banner_Cta"
import TileBlock from "../components/TileBlock/TileBlock"
import AboutComponent from "../components/AboutComponent/AboutComponent"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule";
import AccordionComponent from "../components/Accordion/Accordion"
import ColumnBlock from "../components/ColumnBlock/ColumnBlock"
import AreaGuideSoldProperties from "../components/AreaGuideSoldProperties/AreaGuideSoldProperties"
const ValuationTemplate = ({ data }, props) => {
  const PageData = data?.strapiPage
  return (
    <Layout popularSearch={PageData?.select_popular_search?.title} PageData={PageData}>
      <SEO
        title={
          PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
        }
        description={
          PageData?.seo?.metaDescription
            ? PageData?.seo?.metaDescription
            : PageData?.title
        }
      />
      <BreadcrumbModule choose_menu={PageData.choose_menu} pagename={PageData.title} />

      {PageData.add_page_modules?.map((item, i) => {
        return (
          <div>
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_COLUMN_BLOCK" && (
                <ColumnBlock imagetransforms={
                  PageData.imagetransforms
                } strapi_id={PageData?.strapi_id} {...item} />
              )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_CTA_BANNER" && (
                <Banner_Cta {...item} />
              )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_ACCORDION" &&
              (
                <AccordionComponent {...item} />
              )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
              item?.select_module === "sold_properties" && (
                <AreaGuideSoldProperties {...item} />
              )}
            {item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_ABOUT_SECTION" &&
              <AboutComponent {...item} />
            }
          </div>
        )
      })}
    </Layout>
  )
}

export default ValuationTemplate
export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      imagetransforms {
        banner_section_banner_image_Transforms
        banner_section_logo_image_Transforms
        image_tails_tail_image_Transforms
        column_block_image_Transforms
      }
      
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_COLUMN_BLOCK {
          __typename
          ...COLUMN_BLOCKFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_CTA_BANNER {
          id
          __typename
          ...CTA_BANNERFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          id
          __typename
          select_module
          title
        }    
        ... on STRAPI__COMPONENT_PAGE_MODULES_ABOUT_SECTION {
          __typename
          ...ABOUT_SECTIONFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          __typename
          ...ACCORDIONFragment
        }

      }
    }
  }
`
